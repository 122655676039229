// $sidebar-width: 14rem;
// $topbar-height: 3.6rem;

// @import "base/base";
// @import "layout/layout";
// @import "containers/containers";

//@import '../constants';

// Main
$main-background-colour: #000;
$main-font-colour: #fff;
$main-header-size: 1.6em;
$main-second-header-size: 1.3em;
$main-third-header-size: 1em;
$core-box-background-colour: #7ac143;

// Navigation
$nav-background-colour: rgb(122, 193, 67);
$main-logo-background-colour: rgba(71, 77, 94, 1);
$left-nav-background-colour: #313541;
$drawerWidth: 240px;

// Side nav
$welcome-font-size: 14px;

// Notifications
$notifications-background-colour: #474d5e;

// Theme
$main_light: #7f5500;
$main_main: #f0ac24;
$main_dark: #ffdf9e;
$main_contrastText: #000;
$secondary_light: #535d70;
$secondary_main: #293445;
$secondary_dark: #000d1e;
$secondary_contrastText: #fff;
$blueicon: #2a79f0;
$redicon: #da0000;
$greenicon: #6ada07;
$greyicon: #666666;
$link-color: #1b57b2;

.Root {
  flex-grow: 1;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  word-break: break-word;
  .AppBar {
    z-index: 2;
    @media only screen and (min-width: 960px) {
      margin-left: $drawerWidth;
    }
    .navIconHide {
      @media only screen and (min-width: 960px) {
        display: none;
      }
    }
    .Buttons {
      margin-right: 2%;
      margin-left: auto;
      div {
        float: left;
      }
    }
  }
  .Drawer {
    position: relative;
    @media only screen and (max-width: 959px) {
      z-index: 3;
    }
    > div {
      background: $secondary_dark;
    }
    .setWidth {
      max-width: 240px;
      @media only screen and (min-width: 960px) {
        width: $drawerWidth;
      }
      @media (min-width: 0px) {
        margin-top: 56px;
      }
      @media (min-width: 600px) {
        margin-top: 64px;
      }
      @media (min-width: 960px) {
        margin-top: 0;
      }
      .nav-item {
        color: white !important;
        &.disabled {
          color: #f7f7f7;
        }
      }
    }
  }
  .logo {
    display: block;
    background: $secondary_main !important;
    object {
      display: block;
      margin: auto;
      padding: 10px;
      @media (min-width: 0px) {
        height: 56px;
      }
      @media (min-width: 600px) {
        height: 64px;
      }
    }
  }

  .cealogo {
    display: block;
    padding: 10px;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.Content {
  flex-grow: 1;
  @media only screen and (min-width: 960px) {
    margin-left: $drawerWidth;
  }
  @media (min-width: 0px) {
    margin-top: 56px;
  }
  @media (min-width: 600px) {
    margin-top: 64px;
  }
  .Padded {
    @media (min-width: 0px) {
      padding: 15px;
    }
    @media (min-width: 600px) {
      padding: 30px;
    }
    @media (max-width: 960px) {
      margin-left: 226px;
    }
  }
  .BannerNotification {
    margin: -26px -29px 20px -29px;

    @media (min-width: 0px) {
      padding: 10px 20px;
    }
    @media (min-width: 600px) {
      padding: 20px 30px;
    }
  }
}

.icon {
  &.green {
    fill: $greenicon;
  }
  &.red {
    fill: $redicon;
  }
  &.blue {
    fill: $blueicon;
  }
  &.grey {
    fill: $greyicon;
  }
}

.button {
  &.left {
    margin: 10px;
    float: left;
  }
  &.right {
    margin: 10px;
    float: right;
  }
}

#side-nav-welcome {
  padding: 1rem;

  img {
    max-width: 100%;
  }
}

.paper-detail {
  padding: 20px;
  margin-bottom: 10px;
}

#metadata {
  display: block;
}

.non-interactive {
  cursor: default;
}
